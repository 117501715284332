/* admim side  */
.main-signup-headers {
  border: 1px solid #e1e5ef;
  border-radius: 6px;
  padding: 1.5rem;
}

.add-btn {
  background-color: #00adef;
  border: #00adef;
}

.search-btn {
  background-color: #00adef;
  border: #00adef;
  margin-left: 5px;
}

.game-image {
  width: 50px;
}

.category-image {
  width: 60px;
}


.usersTable .detailsBtn.btn {
  color: #00adef;
  border-color: #00adef;
  transition: all 0.3s;
  font-size: 0.875rem;
  background-color: #fff;
  /* min-width: 88px; */
  display: inline-flex;
  align-items: center;
}

.userModal .modalSubmit {
  background: #4634ff;
  border-color: #4634ff;
}

.enableBtn:hover {
  color: white;
  border: 1px solid #28c76f;
  background: #28c76f;
}

.detailsBtn{
  color: #28a2c7;
  border: 1px solid #28a2c7;
  background: transparent;
}

.disableBtn.btn {
  display: inline-flex;
  align-items: center;
  background: transparent;
  border-color: #ea5455;
  color: #ea5455;

}

.disableBtn:disabled {
  display: inline-flex;
  align-items: center;
  background: white;
  border-color: #ea5455;
  color: #ea5455;
}

.disableBtn:hover {
  background: #ea5455;
  border-color: #ea5455;
  color: white;
}

.container {
  box-sizing: border-box;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.row-contain {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.contain-col {
  background-color: #d9e8fc;
  color: #0162e8;
  /* display: none; */
}

.col-lg-6,
.col-md-6 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: relative;
  width: 100%;
}

.img-row {
  width: 100%;
  text-align: center !important;
}

.img-col {
  width: 100%;
  align-items: center !important;
  margin-top: 20px !important;
}

.content-div {
  background-color: #fff;
  display: block;
  max-width: 100%;
}

.login {
  min-height: 100vh !important;
  align-items: center !important;
  display: flex !important;
}

.login-img {
  width: 80% !important;
  align-items: center !important;
  margin-top: 20px !important;
}

.card-div {
  display: flex !important;
}

.sign-favicon {
  height: 40px;
}

.card-div h1 {
  font-size: 28px !important;
  font-weight: 700 !important;
  color: #364261;
}

.card-sigins h2,
.card-sigins h5 {
  text-align: center;
}

.main-signup-headers h2 {
  color: #0162e8;
  font-weight: 600;
  letter-spacing: -1px;

  font-size: 1.75rem !important;
}

.main-signup-header h2 {
  color: #0162e8;
  font-weight: 600;
  letter-spacing: -1px;

  font-size: 1.75rem !important;
}

.main-signup-header h5 {
  color: #364261;
  font-size: 1.09375rem !important;
  font-weight: 400 !important;
}

.main-signup-header .form-group {
  margin-bottom: 1rem;
}

.pass-input {
  background-clip: padding-box;
  background-color: #fff;
  border: 2px solid #e1e5ef !important;
  border-radius: 3px;
  font-size: 0.875rem;
  font-weight: 400;
  height: 40px;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  display: block;
}

.main-signup-header .form-label {
  color: #a5a0b1;
  display: inline-block;
  margin-bottom: 0.5rem;
}

.signin-btn {
  background-color: #0162e8;
  border-color: #025cd8;
  color: #fff !important;
  border-width: 0;
  line-height: 1.538;
  padding: 9px 20px;
  transition: none;
  display: block;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  user-select: none;
  /* vertical-align: middle; */
}

.main-signup-header .row {
  margin-top: 20px;
  margin-left: -5px;
  margin-right: -5px;
}

.row-xs>div {
  padding-left: 5px;
  padding-right: 5px;
}

.main-signup-header .row>div:first-child .btn {
  background-color: #4267b2;
  color: #fff;
  border: none;
}

.facebook-btn {
  border-width: 0;
  line-height: 1.538;
  padding: 9px 20px;
  transition: none;
  display: block;
  width: 100%;
}

.twitter-btn {
  border-width: 0;
  line-height: 1.538;
  padding: 9px 20px;
  transition: none;
  display: block;
  width: 100%;
  background-color: #00b9ff !important;
  border-color: #00b9ff !important;
  color: #fff !important;
}

.main-signin-footer .createAccount {
  color: #14112d;
  font-weight: 700;
  text-decoration: none;
}

.createAccount:hover {
  color: #0d6efd;
}

.main-signin-footer p:last-child {
  margin-bottom: 0;
  color: #a5a0b1;
}

.dashboardWrapper {
  height: 100vh;
  overflow: hidden;
}

.sidebarWrapper {
  border-right: 1px solid #e3e3e3;
  bottom: 0;
  box-shadow: 0 8px 14.72px 1.28px hsl(270deg 4% 90% / 50%);
  color: #14112d;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  width: 240px;
  z-index: 1024;
}

.rightSideWrapper {
  background-color: #f3f3f9;
  width: auto;
  margin-left: 240px;
  height: 100vh;
  overflow: auto;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  /* padding-left: 20px;
    padding-right: 20px; */
}

.headerWrapper {
  background: #fff;
  color: white;
  border-bottom: 1px solid #dee4ec;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  transition: all 0.5s cubic-bezier(0.4, -0.25, 0.25, 1.1);
  left: 0;
  padding-left: 250px;
  position: fixed;
  width: 100% !important;
  z-index: 975 !important;
  padding: 15px;
}

.outletWrapper {
  padding-top: 65px;
}

.outlet-main {
  background: #fff;
  margin: 25px;
  border-radius: 4px;
  padding: 25px;
}

.slideBtn {
  /* display: none; */
  width: 35px;
  padding: 0;
  color: black;
  font-size: 20px;
  background-color: transparent;
  border: none;
  background: transparent !important;
}

.dashUserIcon img {
  width: 55px;
  border-radius: 50%;
  /* border: 3px solid rgb(255, 255, 255); */
}

.dashUserIcon2 img {
  width: 100%;
  height: 260px;
  object-fit: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.main-header-left {
  align-items: center;
  display: flex;
}

.main-header-center {
  display: flex;
  flex: 1;
  margin: 0 50px;
  position: relative;
}

.main-header-center .form-control {
  padding: 1rem 1.5rem;
}

.main-header-right {
  align-items: center;
  display: flex;

  /* background-color: blue; */
}

.country-Flag img {
  font-size: 0.75rem;
  height: 1rem;
  line-height: 0.1rem;
  width: 1.5rem;
  border-style: none;
  max-width: 100%;
}

.avatar {
  align-items: center;
  background-color: transparent;
  border-radius: 100%;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  position: relative;
  width: 36px;
  align-self: center !important;
  --bs-bg-opacity: 1;
  padding: 0.5rem 0.4rem;
}

.dropdown-menu-left {
  bottom: auto !important;
  left: auto !important;
  right: 0 !important;
  box-shadow: 0 0 15px 1px #bcb5d6;
  border-radius: 0;
  border-width: 1px;
  padding: 0;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(138, 153, 191, 0.125);
  border-radius: 3px;
  color: #031b4e;
}

.nav-link {
  padding: 10px;
}

.main-profile-menu .profile-user img {
  border-radius: 50%;
  height: 37px;
  margin-left: 0;
  width: 37px;
  position: relative;
  top: 5px;
  left: 5px;
}

.main-header-message>a,
.main-header-Notification>a,
.nav-item.full-screen>a {
  border-radius: 50%;
  padding: 0;
  background: #fff;
  color: #272746;
  display: block;
  font-size: 22px;
  height: 40px;
  line-height: 37px;
  margin: auto;
  outline: none;
  position: relative;
  text-align: center;
  width: 40px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.main-header-message,
.main-header-Notification,
.fullscreen-button {
  margin: auto 3px;
  position: relative;
}

.fullscreen-button {
  margin: auto 3px;
}

.fullscreen-button .full-screen-link {
  line-height: 38px !important;
}

.header-icon-svgs {
  color: #5b6e88;
  height: 19px;
  width: 19px;
  margin: 6px;
}

svg {
  overflow: hidden;
}

.pulse-danger {
  background: #ee335e;
  display: block;
  height: 7px;
  right: 8px;
  top: 7px;
  width: 7px;
}

.pulse-danger,
.pulse-danger:before {
  border-radius: 50%;
  position: absolute;
}

.pulse-danger:before {
  /* -webkit-animation: pulse 1s linear infinite; */
  animation: pulse 2s linear infinite !important;
  background: rgba(238, 51, 94, 0.8);
  box-shadow: 0 0 0 rgb(238 51 94 / 90%);
  content: "";
  cursor: pointer;
  height: 15px;
  right: -4px;
  top: -4px;
  width: 15px;
}

.pulse {
  background: #22c03c;
  display: block;
  height: 7px;
  right: 8px;
  top: 7px;
  width: 7px;
}

.pulse,
.pulse:before {
  border-radius: 50%;
  position: absolute;
}

.pulse:before {
  -webkit-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
  background: rgba(34, 192, 60, 0.6);
  box-shadow: 0 0 0 rgb(34 192 60 / 90%);
  content: "";
  cursor: pointer;
  height: 15px;
  right: -4px;
  top: -4px;
  width: 15px;
}

.fe-align-left:before {
  content: "\e907";
}

.header-icon {
  border-radius: 6px !important;
  color: #a8b1c7 !important;
  font-size: 1.5rem !important;
  height: 44px !important;
  line-height: 40px !important;
  line-height: 1.7 !important;
  text-align: center !important;
  max-width: 34px !important;
}

.app-sidebar__toggle {
  background: transparent;
  border-radius: 50%;
  color: #fff;
  color: #272746;
  display: block;
  font-size: 22px;
  height: 40px;
  line-height: 23px;
  margin: auto;
  outline: none;
  position: relative;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 40px;
  z-index: 1;
}

.app-sidebar .side-item.side-item-category {
  color: #2c364c;
  font-size: 11px;
  font-weight: 700;
  height: 15px;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
  padding: 0 20px 0 25px;
  text-transform: uppercase;
}

/* header styles end */
.main-sidebar-header {
  background: #fff;
  border-bottom: 1px solid #eae8f1;
  border-right: 1px solid #eae8f1;
  height: 64px;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  width: 240px;
  z-index: 999;
}

.app-sidebar {
  background: #fff;
  border-right: 1px solid #e3e3e3;
  bottom: 0;
  box-shadow: 0 8px 14.72px 1.28px hsl(270deg 4% 90% / 50%);
  color: #14112d;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  transition: left 0.3s ease, width 0.3s ease;
  width: 240px;
  z-index: 1024;
}

.desktop-logo {
  height: 2rem;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.main-logo {
  align-items: center;
  display: flex;
  width: 200px;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.main-sidemenu {
  margin-top: 70px;
}

.app-sidebar__user {
  align-items: center;
  background-position: 0;
  background-size: cover;
  color: #a8a8a8;
  display: flex;
  display: inline-block;
  width: 100%;
}

.app-sidebar__user .user-pro-body {
  display: block;
  padding: 15px 0;
}

.app-sidebar__user .user-pro-body img {
  background: #fff;
  border: 2px solid #c9d2e8;
  box-shadow: 0 5px 5px 0 rgb(44 44 44 / 20%);
  display: block;
  margin: 0 auto;
  padding: 3px;
}

.user-pro-body .profile-status:before {
  -webkit-animation: pulse 1s linear infinite;
  animation: pulse 1s linear infinite;
  background: rgba(34, 192, 60, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 rgb(34 192 60 / 90%);
  content: "";
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 20px;
}

.avatar-xl {
  font-size: 36px !important;
  height: 72px !important;
  width: 72px !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.app-sidebar__user .user-info {
  margin: 0 auto;
  text-align: center;
}

.app-sidebar__user .user-info h4 {
  color: #14112d;
  font-size: 15px;
}

.app-sidebar__user .user-info .text-muted {
  color: #8991a5 !important;
  font-size: 13px;
}

.side-menu {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.side-menu.side-menu__item {
  border-radius: 0;
  margin: 0;
  padding: 10px 20px 10px 22px;
}

.side-menu__item {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  position: relative;
}

.side-menu__item a {
  color: #6d7790;
  text-decoration: none;
}

.side-menu__label {
  color: #00adef  !important;
}

#bg-side-text {
  position: absolute !important;
  right: 21px !important;
  top: 11px !important;
}

.slide {
  margin: 0;
}

.text-light {
  color: #ecf0fa !important;
}

.ps {
  overflow: auto !important;
  overscroll-behavior-inline: auto;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto !important;
  -ms-touch-action: auto !important;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-top: 25px;
}

/* sidebar styles start */

.slideCloseBtn {
  display: none;
  position: absolute;
  top: 0;
  right: -35px;
  width: 35px;
  height: 35px;
  background-color: #4634ff !important;
  color: #ffffff;
  border: none;
  font-size: 24px;
  border-radius: 0;
  padding: 0;
}

.dashboardLogo img {
  max-height: 40px;
}

.sidebarScrollDiv {
  height: calc(100vh - 85px);
  overflow-y: auto;
}

/* width */
.sidebarScrollDiv::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebarScrollDiv::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgb(51, 51, 51, 0.2);
}

/* Handle */
.sidebarScrollDiv::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 5px;
}

.side-menu {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.sidebarMenuItem {
  margin-top: 5px;
}

.sidebarMenuItem a {
  color: #8991a5;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bolder;
  padding: 12px 25px;
  transition: all 0.3s;
  border-left: 3px solid transparent;
  font-size: 0.8125rem;
  letter-spacing: 0.5px;
  line-height: 1;
}

.active-color {
  background-color: #dbdbdb !important;
}

.sidebarMenuItem a.active,
.sidebarMenuItem a:hover,
.sidebarMenuItem.activeMenu>a {
  /* .toggleMenu.openToggle>a { */
  background-color: rgb(243, 243, 243) !important;
}

.sidebarMenuItem .toggleUl a:focus,
.sidebarMenuItem .toggleUl a:hover {
  color: #00adef;
}

.sidebarMenuItem a span {
  font-size: 1.125rem;
  margin-right: 15px;
  transition: all 0.5s;
  font-weight: 700;
  text-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
}

.toggleMenu>a {
  position: relative;
}

.toggleMenu>a::after {
  content: "\f078";
  font-family: "Font Awesome 6 Free";
  font-weight: 700;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 11px;
  transition: all 0.3s linear;
}

.toggleMenu.openToggle>a::after {
  transform: translateY(-50%) rotate(-180deg);
}

.openToggle .toggleUl {
  height: auto;
  max-height: 500px;
}

.toggleUl .toggleItem {
  margin-top: 0;
}

.toggleUl .toggleItem a {
  padding: 10px 20px 10px 35px;
  font-size: 0.75rem;
  line-height: initial;
  background-color: whitesmoke;
}

.toggleUl .toggleItem a span {
  font-size: 0.75rem;
}

.sidebarMenuTitle {
  font-size: 0.6875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #b5b5b5;
  margin: 25px 0 8px 25px;
}

.toggleUl {
  padding: 0 0;
  list-style: none;
  max-height: 0px;
  transition: max-height 0.5s linear;
  overflow: hidden;
  background: #ffffff0f;
}

/* sidebar styles end */

/* Dashboard styles start */
.dashboard-header {
  display: flex;
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100%;
  justify-content: space-between !important;
}


.main-content-title {
  color: #242f48;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;
  text-indent: -1px;
}

.tx-24 {
  font-size: 24px;
}

.mg-b-1 {
  margin-bottom: 1px;
}

.mg-b-0 {
  margin-bottom: 0;
}

.F p {
  color: #78829a;
  font-weight: 400;
}

@media screen and (min-width: 634px) {
  .main-dashboard-header-right {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .main-dashboard-header-right {
    margin-top: 0;
  }
}

@media (min-width: 576px) {
  .main-dashboard-header-right {
    flex-wrap: nowrap;
  }

  .outlet-main {
    background: #fff;
    margin:25px !important;
    border-radius: 4px;
    padding: 25px;
  }
}

.main-dashboard-header-right {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.main-dashboard-header-right>div label {
  color: #78829a;
  display: block;
  margin-bottom: 0 !important;
}

@media (min-width: 768px) {
  .main-dashboard-header-right>div {
    text-align: right;
  }
}

.tx-13 {
  font-size: 13px;
}

.main-star {
  align-items: center;
  display: flex;
}

.main-star i.active {
  color: #fbbc0b;
}

.main-star i.typcn {
  line-height: 0.9;
}

.main-star i {
  color: #97a3b9;
  display: inline-block;
  font-size: 20px;
  line-height: 0;
}

.typcn-star:before {
  content: "\e109";
}

.typcn:before {
  font-family: "typicons";
  font-style: normal;
  font-weight: normal;
  /* speak: none; */
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  height: 1em;
  font-size: 1em;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  /* font-smoothing: antialiased; */
  text-rendering: optimizeLegibility;
}

.main-star span {
  display: inline-block;
  font-size: 13px;
  margin-left: 5px;
}

@media (min-width: 992px) {
  .main-dashboard-header-right>div+div {
    margin-left: 50px;
  }
}

@media (min-width: 576px) {
  .main-dashboard-header-right>div+div {
    flex-shrink: 0;
    margin-left: 25px;
    margin-top: 0;
  }
}

.main-dashboard-header-right>div+div {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .main-dashboard-header-right>div {
    text-align: right;
  }
}

.main-dashboard-header-right>div label {
  color: #78829a;
  display: block;
  margin-bottom: 0;
}

.row-sm {
  margin-left: -10px !important;
  margin-right: -10px;
}

.row-sm>div {
  padding-left: 10px;
  padding-right: 10px;
}

/* @media (min-width: 992px) {
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
} */
.card {
  overflow: hidden !important;
  border-radius: 7px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #deebfd;
  border-radius: 5px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.sales-card {
  background-image: linear-gradient(270deg, #0db2de 0, #005bea) !important;
  overflow: hidden !important;
  border-radius: 7px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #deebfd;
  border-radius: 5px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.today-card {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
  overflow: hidden !important;
  border-radius: 7px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #deebfd;
  border-radius: 5px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.earn-card {
  background-image: linear-gradient(270deg, #48d6a8 0, #029666) !important;
  overflow: hidden !important;
  border-radius: 7px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #deebfd;
  border-radius: 5px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.sold-card {
  background-image: linear-gradient(270deg, #efa65f, #f76a2d) !important;
  overflow: hidden !important;
  border-radius: 7px;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #deebfd;
  border-radius: 5px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.sales-head {
  color: #fff !important;
  font-size: 12px;
}

.card-amount {
  font-size: 20px !important;
  color: #fff !important;
  font-weight: 700 !important;
}

.card-para {
  color: #fff !important;
  font-size: 22px;
  opacity: 0.7;
}

.card-span {
  float: right !important;
  font-size: 30px;
  color: #fff;
}

.card-span2 {
  color: #fff !important;
  opacity: 0.7;
}

#compositeline,
#compositeline2 {
  bottom: -1px;
  position: relative;
}

#compositeline2 canvas,
#compositeline3 canvas,
#compositeline4 canvas,
#compositeline canvas {
  width: 100% !important;
}

.order-status-card {
  word-wrap: break-word;
  background: #fff !important;
  border: 1px solid #deebfd;
  border-radius: 7px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.card-header {
  background-color: transparent;
  /* border-color: #d0d7e8; */
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  position: relative;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.status-card-header {
  background-color: transparent;
  border-color: #d0d7e8;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  position: relative;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

.status-card-header:first-child {
  border-radius: 0;
}

.status-card-header:first-child {
  border-radius: 2px 2px 0 0;
}

.status-card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.order-status-div {
  display: flex !important;
  justify-content: space-between !important;
}

.order-status-title {
  color: #242f48;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

.mdi-dots-horizontal:before {
  content: "\F1D8";
}

.status-card-p {
  color: #7987a1 !important;
  font-size: 12px;
}

.status-card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.total-revenue {
  display: flex;
  flex: none;
  padding: 0 10px 20px;
  position: absolute;
  z-index: 9;
}

@media (max-width: 1199px) {
  .total-revenue {
    border-bottom: 0 solid #e3e8f7 !important;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
}

.total-revenue label span {
  border-radius: 100%;
  display: inline-block;
  height: 9px;
  margin-right: 5px;
  width: 9px;
  /* background-color: #0162e8 !important; */
  color: #fff;
}

.total-revenue h4 {
  color: #1c273c;
  font-family: Archivo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 3px;
}

.total-revenue>div+div {
  margin-left: 20px;
  padding-left: 20px;
}

.sales-bar {
  bottom: -21px;
  min-height: 180px !important;
  position: relative;
}

.card-dashboard-map-one {
  border-color: #e2e8f5;
  padding: 20px;
  word-wrap: break-word;
  background: #fff !important;
  border: 1px solid #deebfd;
  border-radius: 7px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.main-content-label {
  font-size: #242f48;
  color: #242f48;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.card-dashboard-map-one span {
  color: #7987a1 !important;
  font-size: 12px;
  display: block !important;
}

#bar .apexcharts-canvas,
#bar svg {
  width: 100% !important;
}

.apexcharts-canvas {
  position: relative;
  user-select: none;
}

.apexcharts-canvas {
  position: relative;
  user-select: none;
  /* overflow: hidden; */
}

@media (min-width: 992px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 270px !important;
  }
}

@media (min-width: 576px) {
  .card-dashboard-map-one .vmap-wrapper {
    height: 300px !important;
  }
}

.card-dashboard-map-one .vmap-wrapper {
  height: 200px;
  padding-left: 20%;
  padding-right: 10%;
}

.recent-customers {
  border-color: #e2e8f5;
  padding: 20px;
  word-wrap: break-word;
  background: #fff !important;
  border: 1px solid #deebfd;
  border-radius: 7px;
  box-shadow: -8px 12px 18px 0 #dadee8;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.3rem;
  min-width: 0;
  position: relative;
}

.recent-card-header {
  border-color: #d0d7e8;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  position: relative;
  border-bottom: 0;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  border-radius: 0;
  background-color: #ffffff;
}

.recent-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush:first-child .customers.list-group-item:first-child {
  border-top: 0;
}

.customers .list-group-item {
  padding: 0.82rem 1.25rem;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0;
}

.list-group-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.list-group-item-action {
  color: #1d2635;
  text-align: inherit;
  width: 100%;
}

.recent-item {
  background-color: #fff;
  border: 1px solid #e7ebf3;
  display: block;
  margin-bottom: -1px;
  padding: 0.75rem 1.25rem;
  position: relative;
}

.media {
  align-items: flex-start;
  display: flex;
}

.customers .list-group-item .media img {
  height: 40px !important;
  width: 40px !important;
}

.avatar-lg {
  font-size: 28px !important;
  height: 64px !important;
  width: 64px !important;
}

.media-body {
  flex: 1;
}

.media-content h5 {
  font-size: 15px;
  font-weight: bold !important;
}

.media-content p {
  color: #7987a1 !important;
  font-size: 13px;
  --bs-text-opacity: 1;
}

.media-content span {
  color: #22c03c !important;
  --bs-text-opacity: 1;
  font-weight: bold;
}

.list-group-item.br-t-1 {
  border-top: 1px solid #e7ebf3;
}

.customers .list-group-item {
  padding: 0.82rem 1.25rem;
}

.product-timeline ul.timeline-1 {
  list-style-type: none;
  position: relative;
}

.product-timeline ul.timeline-1:before {
  border-left: 2px dotted #c0ccda;
  bottom: 0;
  content: " ";
  content: "";
  display: inline-block;
  height: 94%;
  left: -2px;
  margin: 20px 20px 0 22px;
  position: absolute;
  z-index: 0;
}

#mrg-8 {
  margin-right: 8px;
}

.product-timeline ul.timeline-1>li {
  margin: 20.7px 0;
  padding-left: 1rem;
}

.product-timeline ul.timeline-1>li .product-icon {
  background-color: #f3f6f7;
  border-radius: 50%;
  font-size: 20px;
  font-size: 17px;
  height: 37px;
  left: 3px;
  line-height: 37px;
  position: absolute;
  text-align: center;
  width: 37px;
}

.text-white {
  color: #fff !important;
}

.bg-primary-gradient {
  background-image: linear-gradient(270deg, #0db2de 0, #005bea) !important;
}

.ti-pie-chart:before {
  content: "\e684";
}

.bg-danger-gradient {
  background-image: linear-gradient(45deg, #f93a5a, #f7778c) !important;
}

.bg-success-gradient {
  background-image: linear-gradient(270deg, #48d6a8 0, #029666) !important;
}

.bg-warning-gradient {
  background-image: linear-gradient(270deg, #efa65f, #f76a2d) !important;
}

.bg-purple-gradient {
  background-image: linear-gradient(90deg, #673ab7 0, #884af1) !important;
}

.mdi-cart-outline:before {
  content: "\F111";
}

.ti-bar-chart-alt:before {
  content: "\e6bb";
}

#chart {
  margin-bottom: 4.5rem;
}

#chart div:first-child {
  margin-left: auto;
  margin-right: auto;
}

.apexcharts-canvas {
  position: relative;
  user-select: none;
}

.sales-infomation {
  bottom: 0;
  position: absolute;
}

.bg-info,
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #00b9ff !important;
}

.legend {
  border-radius: 0;
  display: block;
  height: 8px;
  margin-right: 10px;
  margin-top: 6px;
  width: 8px;
}

.progress-style.progress {
  overflow: visible !important;
}

.progress-sm {
  height: 4px;
}

.progress {
  background-color: #dde2ef;
  border-radius: 3px;
  display: flex;
  font-size: 0.65625rem;
  overflow: hidden;
}

.progress {
  border-radius: 0;
  height: auto;
}

.progress-style.progress .progress-bar {
  -webkit-animation: animate-positive 2s;
  animation: animate-positive 2s;
  border-radius: 0;
  box-shadow: none;
  position: relative;
}

.progress-style .progress-bar {
  background-color: #337ab7;
  border-radius: 20px !important;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 15%);
  color: #fff;
  float: left;
  font-size: 12px;
  height: 100%;
  line-height: 20px;
  overflow: visible !important;
  text-align: center;
  transition: width 0.6s ease;
  width: 75px;
}

.progress-style .bg-danger-gradient.progress-bar:after {
  border: 4px solid #ee335e;
}

.progress-style.progress .progress-bar:after {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 5px rgb(0 0 0 / 20%);
  content: "";
  height: 15px;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 15px;
  border: 4px solid #ee335e;
  /* background-image: linear-gradient(270deg, #0db2de 0, #005bea) !important; */
}

.bg-primary-gradient {
  background-image: linear-gradient(270deg, #0db2de 0, #005bea) !important;
}

/* dashboard Styles End */

/* userlist styles start */
.btn-b {
  background-color: #00b9ff !important;
  border-color: #00b9ff !important;
  color: #fff !important;
}

.btn-icon {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 38px;
  justify-content: center;
  width: 38px;
  color: #fff !important;
}

.togle-btn {
  border-width: 0 !important;
  line-height: 1.538;
  padding: 9px 20px;
  transition: none;
  height: 3rem;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.content-title {
  font-size: 1.3125rem;
  font-weight: bold !important;
}

.text-muted {
  color: #7987a1 !important;
  font-size: 13px;
}

.card-header:first-child {
  border-radius: 0;
}

.table-card-p {
  font-size: 12px;
  color: #949eb7;
}

.head-color{
  background-color: #00adef29;
}

.td-active {
  color:#029666!important;
  border-color: #029666!important;
  background-color: white!important;
  border-radius: 0.25rem!important;
  transition: all 0.33s!important;
  padding: 4px 8px!important;
  margin-left: 0.25rem!important;
  background-color: rgba(40, 199, 111, 0.1) !important;
  border: 1px solid #029666!important;
}

.td-inactive {
  color:red!important;
  border-color:red!important;
  background-color: white!important;
  border-radius: 0.25rem!important;
  transition: all 0.33s!important;
  padding: 4px 8px!important;
  margin-left: 0.25rem!important;
  background-color: rgba(153, 100, 100, 0.1)!important;
  border: 1px solid red!important;
}


.userlist-table {
  border-top: 1px solid #e3e8f7 !important;
}

.card-title {
  color: #242f48;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .userlist-table .table {
    white-space: nowrap !important;
  }
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
  width: 100%;
}

.userlist-table .table td,
.userlist-table .table th {
  display: table-cell;
  padding: 0.75rem;
  vertical-align: middle;
}

.userlist-table .table th {
  border-bottom-width: 1px;
  border-top-width: 0;
  color: #37374e;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  /* padding: 0 15px 5px; */
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .wd-lg-20p {
    width: 20%;
  }
}

.table> :not(:last-child)> :last-child>* {
  border-bottom: #dde2ef;
}

.table-vcenter td,
.table-vcenter th {
  border-top: 1px solid #f1f5fa;
  vertical-align: middle;
}

.table thead td {
  border-bottom-width: 1px;
  border-top-width: 0;
  color: #37374e;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 0 15px 5px;
  text-transform: uppercase;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background: #e1e6f1;
  --bs-table-accent-bg: $background !important;
  color: var(--bs-table-striped-color);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ecf0fa;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(238, 238, 247, 0.5);
}

.border-top {
  border-top: 1px solid #e3e8f7 !important;
}

.card-header {
  border-bottom: 0;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
}

table {
  border-collapse: collapse;
}

.table th {
  line-height: 1.462;
  padding: 9px 15px;
  text-align: inherit;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ecf0fa;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(238, 238, 247, 0.5);
}

.userlist-table img {
  max-width: inherit;
}

.me-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.avatar-md {
  font-size: 24px !important;
  height: 48px !important;
  width: 48px !important;
}

.userlist-table .btn-sm {
  display: initial;
  font-size: 0.9rem;
  margin: 4px;
  padding: 0.44rem 0.7rem;
}

.userlist-table .dot-label {
  -webkit-animation: animate 2s linear infinite;
  animation: animate 2s linear infinite;
  border-radius: 50%;
  bottom: -7px;
  height: 6px;
  left: -6px;
  position: relative;
  width: 6px;
}

@keyframes animate {
  20% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1.5);
  }
}

.bg-gray-300 {
  background-color: #d0d7e8;
}

/* userlist styles end */
.text-muted1 {
  color: #7987a1 !important;
}

.accordion>.card:first-of-type {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion>.card .card-header {
  margin-bottom: -1px;
}

.card-header:first-child {
  border-radius: 0;
}

.accordion .card-header {
  background-color: transparent;
  font-weight: 500;
  padding: 0;
}

.accordion-dark .card-header a.collapsed {
  background-color: #242f48;
  color: hsla(0, 0%, 100%, 0.8);
  font-weight: 500;
  letter-spacing: -0.1px;
}

.accordion .card-header a {
  background-color: #ecf0fa;
  color: #242f48;
  display: block;
  font-size: 14px;
  font-weight: 500;
  padding: 14px 20px;
  position: relative;
  text-decoration: none;
}

.panel-heading1.bg-primary .panel-title1 a,
.panel-title1 a {
  color: #fff !important;
  display: block;
  text-decoration: none;
}

.panel-heading1.bg-primary .panel-title1 a {
  color: #6a7882;
}

.panel-title1 a {
  font-size: 15px;
  font-weight: 400;
  padding: 15px;
  position: relative;
}

.border {
  border: 1px solid #e3e8f7 !important;
}

.panel-body {
  padding: 15px;
}

.accor.bg-primary h4 a {
  color: #fff;
  text-decoration: none;
}

.accor h4 a,
.alert {
  padding: 0.75rem 1.25rem;
}

.accordion .card-body {
  background-color: #ecf0fa;
  padding: 0 20px 20px;
  transition: none;
}

.accor h4 a {
  border-bottom: 1px solid rgba(154, 180, 202, 0.16);
  display: block;
  font-size: 15px;
}

.card-footer:last-child {
  border-radius: 0 0 2px 2px;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-footer {
  border-bottom-left-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.card-footer,
.card-header {
  border-color: #d0d7e8;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
  position: relative;
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
}

.main-table-reference {
  background-color: #fff;
}

.main-table-reference>thead>tr>td,
.main-table-reference>thead>tr>th {
  background-color: #ecf0fa;
  border-bottom: 0;
  color: #737f9e;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.main-table-reference>tbody>tr>td,
.main-table-reference>tbody>tr>th,
.main-table-reference>thead>tr>td,
.main-table-reference>thead>tr>th {
  border: 1px solid #e3e8f7;
  font-size: 13px;
  padding: 8px 10px;
}

.editInput {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid #e1e5ef;
  border-radius: 3px;
  font-size: 0.875rem;
  font-weight: 400;
  height: 40px;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.editInput:focus {
  background-color: #fff;
  border-color: #b3c6ff;
  box-shadow: none;
  color: #4d5875;
  outline: 0;
}

.file-browser .btn-default {
  background: #f1f4fb !important;
  border: 1px solid #e2e8f5 !important;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-radius: 0;
  color: #68769a !important;
  padding: 0.56rem 15px !important;
}

.editBtn.btn {
  border-width: 0;
  line-height: 1.538;
  padding: 9px 20px;
  transition: none;
  border: 1px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 0.875rem;
  background-color: #00adef;
}

.cancelBtn.btn {
  background-color: #737f9e;
  border-color: #737f9e;
  color: #fff;
  border-width: 0;
  line-height: 1.538;
  padding: 9px 20px;
  transition: none;
  border: 1px solid transparent;
  border-radius: 3px;
  display: inline-block;
  font-size: 0.875rem;
  margin: 10px;
}



.main-profile-overview .main-img-user {
  height: 120px;
  margin-bottom: 20px;
  width: 120px;
}

.main-img-user {
  border-radius: 100%;
  display: inline-block;
  height: 36px;
  position: relative;
  text-align: center;
  width: 36px;

  height: 40px;
  width: 40px;
}

.main-img-user img {
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.main-img-user img {
  height: 100% !important;
  width: 100% !important;
}

.profile-user .profile-edit {
  background: #d5d4e0;
  border-radius: 50%;
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 30px;
}

.main-profile-name {
  color: #242f48;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 2px;
}

.main-profile-name-text {
  color: #737f9e;
  font-size: 13px;
  margin-bottom: 0;
}

.main-profile-bio {
  font-size: 13px;
  margin-bottom: 20px;
}

.main-profile-bio a:hover {
  color: #0661e0;
}

.main-profile-bio a,
.main-profile-bio a:hover {
  text-decoration: none;
}

.main-profile-bio a:hover {
  color: #0a58ca;
}

.main-profile-bio a {
  background-color: transparent;
  color: #0162e8;
}

.main-profile-bio a {
  color: #0d6efd;
  text-decoration: underline;
}

.main-profile-bio a:-webkit-any-link {
  cursor: pointer;
  text-decoration: none;
}

.main-content-label {
  color: #242f48;
  font-weight: 700;
  letter-spacing: 0.2px;
  line-height: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.main-profile-social-list .media {
  align-items: center;
}

.media {
  align-items: flex-start;
  display: flex;
}

.main-profile-social-list .media {
  align-items: center;
}

.media {
  align-items: flex-start;
  display: flex;
  margin-bottom: 20px;
}

.main-profile-social-list .media-body {
  margin-left: 20px;
}

.media-body {
  flex: 1;
}

.media-body a {
  display: block;
  text-decoration: none;
}

.media-primary {
  color: #0162e8 !important;
}

.media-primary-twitter {
  color: #5d9c59;
}

.media-primary-portfolio {
  color: #ffacac;
}

.main-profile-social-list .media-icon {
  font-size: 34px;
  line-height: 0;
  width: 30px;
}

.progress {
  background-color: #dde2ef;
  border-radius: 3px;
  display: flex;
  font-size: 0.65625rem;
  overflow: hidden;
  border-radius: 0;
  height: auto;
}

.progress-bar {
  background-color: #0162e8;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 0.6s ease;
  white-space: nowrap;
}

.progress-bar {
  border-radius: 0;
  font-size: 11px;
  height: 10px;
}

.bg-primary-transparent {
  background-color: #d9e8fc;
}

.counter-icon {
  background: rgba(21, 22, 23, 0.2);
  border-radius: 50%;
  display: inline-flex;
  height: 4rem;
  margin-bottom: 0;
  padding: 1.3rem 1.4rem;
  text-align: center;
  width: 4rem;
}

.counter-icon i {
  color: #fff;
  font-size: 22px;
}

.profile.navtab-custom {
  padding: 11px 0 10px;
}

.nav-tabs {
  border-bottom: 1px solid #e3e8f7;
  border-bottom-width: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.profile.navtab-custom .active a,
.profile.navtab-custom a.active {
  background: #ecf0fa;
  border-bottom: 0;
  color: #000;
}

.profile.navtab-custom li a {
  background: transparent;
  border: 1px solid #e3e6f0;
  border-bottom: 0;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: #000;
  font-size: 13px;
  margin-right: 7px;
  padding: 13px;
}

.custom-select-container {
  position: relative;
  max-width: 100%;
  width: 100%;
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #e1e6f1;
  border-radius: 0;
  cursor: pointer;
}

.custom-select.open {
  z-index: 1;
}

.custom-select .selected-option {
  color: #99a6b7;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom-select .dropdown-icon {
  font-size: 1rem;
  color: #99a6b7;
}

.custom-select .dropdown-icon i {
  transition: all 0.2s ease-in-out;
}

.custom-select.open .dropdown-icon i {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: 43px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #e1e6f1;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.option {
  padding: 10px 15px;
  color: black;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #e1e6f1;
}

.option:hover {
  background-color: #e1e6f1;
}

.custom-select-container {
  position: relative;
  max-width: 100%;
  width: 100%;
}

.custom-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #e1e6f1;
  border-radius: 0;
  cursor: pointer;
}

.custom-select.open {
  z-index: 1;
}

.custom-select .selected-option {
  color: #99a6b7;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.custom-select .dropdown-icon {
  font-size: 1rem;
  color: #99a6b7;
}

.custom-select .dropdown-icon i {
  transition: all 0.2s ease-in-out;
}

.custom-select.open .dropdown-icon i {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: 43px;
  left: 0;
  right: 0;
  width: 100%;
  max-height: auto;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #e1e6f1;
  border-top: none;
  border-radius: 0 0 4px 4px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.option {
  padding: 10px 15px;
  color: black;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #e1e6f1;
}

.option:hover {
  background-color: #e1e6f1;
}

/* / cue select end / */

/* ------------- Admin Login Start  --------*/

.loginBg {
  background-image: url("../Images/backgrounds/login.jpg");
  background-color: #3d2bfb;
  padding: 100px 0;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.loginBg::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e157d;
  opacity: 0.57;
  z-index: -1;
}

@media screen and (min-width: 520px) {
  .loginDiv {
    width: 520px !important;
    color: white;
    background-color: #1e157d;
    border-radius: 10px;
    position: relative;
  }
}

.loginDiv {
  /* width: 520px !important; */
  color: white;
  background-color: #1e157d;
  border-radius: 10px;
  position: relative;
}

.loginDiv::after {
  position: absolute;
  content: "";
  width: 247px;
  height: 247px;
  right: -80px;
  top: -100px;
  border: 40px solid rgba(52, 34, 229, 0.31);
  box-sizing: border-box;
  filter: blur(2px);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.loginTop {
  padding: 60px 30px 40px 30px;
  text-align: center;
  background-color: #3d2bfb;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  z-index: 1;
  text-align: center;
}

.loginTop::after {
  position: absolute;
  content: "";
  bottom: -25px;
  left: 5px;
  right: 5px;
  z-index: -1;
  border-width: 25px 256px 0px 256px;
  border-style: solid;
  border-color: #3d2bfb transparent transparent transparent;
}

.loginTop .loginTitle {
  font-size: 30px;
  font-weight: 600;
  color: white;
}

.loginTop p {
  font-size: 17px;
}

.loginBottom {
  padding: 50px 40px 40px 40px;
}

.loginBottom .loginForm {
  margin-top: 30px;
}

.loginForm label {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 500;
}

.loginForm .form-control {
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  font-size: 16px;
  padding: 20px;
  background: transparent;
  box-shadow: none;
}

.loginForm .form-check {
  position: relative;
  padding-left: 0;
}

.loginForm .form-check-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  margin-left: 0;
  opacity: 0;
}

.loginForm .form-check .form-check-label {
  position: relative;
  padding-left: 25px;
  font-size: 14px;
}

.loginForm .form-check .form-check-label::before {
  position: absolute;
  content: "";
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  top: 3px;
  left: 0;
  width: 15px;
  height: 15px;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  transition: all 0.3s;
}

.loginForm .form-check .form-check-input:checked~.form-check-label::before {
  content: "\f00c";
  background: #3d2bfb;
  border-color: #3d2bfb;
}

.forgetPwd {
  color: white !important;
  text-decoration: underline;
  font-size: 14px;
}

.loginBtn {
  background-color: #3d2bfb !important;
  color: white;
  height: 50px;
  margin-top: 40px;
  border: none;
}

/* ===================== RESPONSIVE */
@media (max-width: 575px) {
  .loginTop {
    padding: 40px 15px;
  }

  .loginTop .loginTitle {
    font-size: 24px;
  }

  .loginTop p {
    font-size: 14px;
  }

  .loginTop::after {
    display: none;
  }

  .loginBottom {
    padding: 30px 20px;
  }
}

/* ------------- Admin Login End  --------*/
/* cue select end */

.app-sidebar__togglee {
  display: none;
}

.toggleBtn {
  margin: 10px 0 10px 10px;
  font-size: 20px;
  color: #000;
  align-self: center;
}



@media (max-width: 767px) {

  .main-card-signin,
  .main-signup-header {
    border: 1px solid #e1e5ef;
    border-radius: 6px;
    padding: 1.5rem;
  }

  .login-img {
    display: none;
    /* margin-top: 60% !important; */
  }
}

@media (min-width: 992px) {
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }

  .container {
    max-width: 720px;
  }

  .login-img {
    width: 80% !important;
    align-items: center !important;
    margin-top: 80px !important;
  }

  .img-col {
    /* padding-top: 40%; */
    margin-top: 10% !important;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .col-md-7 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .col-md-6 {
    flex: 0 0 40%;
    max-width: 40%;
  }
}


@media (min-width: 1200px) {
  .wd-xl-80p {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .wd-md-100p {
    width: 100%;
  }
}


@media (min-width: 768px) {
  .main-header {
    left: 0;
    padding-left: 250px;
    position: fixed;
    width: 100% !important;
    z-index: 975 !important;
  }
}

.main-header {
  border-bottom: 1px solid #dee4ec;
  box-shadow: 5px 7px 26px -5px #cdd4e7;
  margin-bottom: 20px;
  background: #fff;
  height: 64px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

@media (min-width: 992px) {
  .main-header-left .search-btn {
    background-color: transparent;
    color: #b4bdce;
    font-size: 16px;
    height: 40px;
    padding-right: 13px;
    position: absolute;
    right: 12px;
    top: 0;
    transition: none;
    border: none;
  }
}

@media (min-width: 1135px) {
  .main-header-left .form-control {
    width: 350px;
  }
}

@media (min-width: 992px) {
  .main-header-left .form-control {
    background: #ecf0fa;
    border: 1px solid #ecf0fa;
    border-radius: 20px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .main-content-title {
    margin-bottom: 40px;
  }
}


@media (min-width: 768px) {
  .app.sidenav-toggled .app-sidebar {
    left: 0;
    overflow: visible;
    width: 80px;
  }
}

@media (min-width: 768px) {
  .sidenav-toggled .app-sidebar {
    left: 0;
  }
}


@media (min-width: 768px) {
  .main-header {
    left: 0;
    padding-left: 250px;
    position: fixed;
    width: 100% !important;
    z-index: 975 !important;
  }
}

.main-header {
  border-bottom: 1px solid #dee4ec;
  box-shadow: 5px 7px 26px -5px #cdd4e7;
  margin-bottom: 20px;
  background: #fff;
  height: 64px;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

@media (min-width: 992px) {
  .main-header-left .search-btn {
    background-color: transparent;
    color: #b4bdce;
    font-size: 16px;
    height: 40px;
    padding-right: 13px;
    position: absolute;
    right: 12px;
    top: 0;
    transition: none;
    border: none;
  }
}

@media (min-width: 1135px) {
  .main-header-left .form-control {
    width: 350px;
  }
}

@media (min-width: 992px) {
  .main-header-left .form-control {
    background: #ecf0fa;
    border: 1px solid #ecf0fa;
    border-radius: 20px;
    height: 40px;
  }
}

/* log in page start */

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.login-container {
  text-align: center;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
}

.sign-in {
  width: 360px;
  margin: 0 auto;
  /* height: 50vh; */
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 32px;
  font-size: 17px;
  background-color: white;
  transform: translate(-0%, +0%);
}

.arrow a {
  cursor: pointer;
  color: #000;
}

.user-aigamer {
  width: 200px;
  margin-left: 33px;
}

.lable-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  padding: 11px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #CACACA;
  appearance: none;
  border-radius: 3px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.mail .mail-input input,
.signupMobile.react-tel-input .form-control {
  background-color: transparent;
  color: black;
  padding: 14px;
  padding-left: 60px;
  border-radius: 3px;
  font-size: 13px;
  width: 100%;
}

.signupMobile.react-tel-input .form-control {
  padding: 23px;
  padding-left: 60px;
}

.signupMobile.react-tel-input .flag-dropdown,
.signupMobile.react-tel-input .flag-dropdown.open {
  background-color: transparent;
  border: none;
  padding-left: 12px;
}

.signupMobile.react-tel-input .flag-dropdown.open .selected-flag,
.signupMobile.react-tel-input .flag-dropdown .selected-flag:hover {
  background-color: transparent;
}

.signupMobile .country-list .country-name {
  color: #000 !important;
}

.signupMobile .country-list {
  text-align: start;
}


.signupSubmitBtn.btn {
  width: 100%;
  padding: 12px 20px;
  /* background-image: linear-gradient(to right, #2E0FFF, #1C95B0); */
  background-color: #05305cf2;
  border: none;
  border-radius: 15px;
  height: 42px;
  font-size: 13px;
  display: block;
  line-height: 16.9px;
  font-weight: 600;
  color: var(--darkbg) !important;
  text-align: center;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.4) 4px 4px 4px;
}

.facebook-icon .fa-brands,
.fab {
  font-weight: 400;
  border: 1px solid #bbbec3;
  padding: 10px;
  border-radius: 50%;
  height: 44px;
  padding-top: 12px;
  text-align: center;
  min-width: 44px;
  font-size: 24px;
  line-height: 18px;
}

.whatsapp-icon i{ 
  font-weight: 400;
  border: 1px solid #bbbec3;
  padding: 10px;
  border-radius: 50%;
  height: 44px;
  padding-top: 12px;
  text-align: center;
  min-width: 44px;
  font-size: 25px;
  color: #22c03c;
  line-height: 18px;
}

.main-user .fa-user {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 9px;
  cursor: pointer;
  border-radius: 50%;
}

.email-otp {
  font-size: 14px;
  color: #687179;
  /* margin-bottom: 80px; */
}

.form-input {
  width: 204px;
  display: flex;
}

.form-box {
  width: 8px;
}

.form-input input {
  text-align: center;
  height: 42px;
  width: 42px;
  border-radius: 2px;
  background-color: #070707;
  border: 1px solid #687179;
  color: black;
}

.resend {
  font-size: 14px;
}

.rightCanvas {
  z-index: 1150;
  background-color: rgb(26, 27, 40);
}

.closeIcon {
  font-size: 22px;
}

.profileUser {
  font-size: 15px;
}

.mainUser {
  margin-top: 10px;
}

.edit-input {
  border-bottom: 1px solid #2c2c2c;
  border-radius: 5px;
}

.edit-input-value button {
  background-color: transparent;
  color: white;
  border: none;
}

.edit-mail {
  color: white;
  font-size: 12px;
  opacity: 0.5;
}

.edit-mail i {
  color: white;
  font-size: 16px;
  height: 16px;
  width: 16px;
}

.edit-mail .enter {
  font-size: 16px;
}

.main-profile-overview .main-img-user {
  height: 75px;
  margin-bottom: 20px;
  width: 75px;
}

.main-profile-overview .main-img-user {
  height: 75px;
  margin-bottom: 20px;
  width: 75px;
}

.main-img-user {
  border-radius: 100%;
  display: inline-block;
  height: 36px;
  position: relative;
  text-align: center;
  width: 36px;
  height: 40px;
  width: 40px;

}

.main-img-user img {
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.main-img-user img {
  height: 100% !important;
  width: 100% !important;
  /* border: 1px solid #c6c0c0; */
}


.profile-user .profile-edit {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  background: #cac9cf;
  color: white;
  border: none;
  padding: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.profile-user .profile-edit:hover {
  background: #a5a0b1;
}

.profile-user .profile-edit input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.editUser {
  text-decoration: none;
  font-size: 16px;
  font-weight: bolder;
  color: #005bea;
}

.editUser:hover {
  color: #005bea;
}

.mainChange {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  background-color: rgb(33, 34, 51);
  border-radius: 8px;
  padding: 10px;
}

.change-user {
  font-size: 20px;
  font-weight: bolder;
}

.changeUser {
  border: 1px solid white;
  width: 38%;
  padding: 9px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;

}

.confirm button{
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  font-size: 15px;
  font-weight: bolder;
  background-color: rgb(104, 66, 255);
}

/* log in page end */

.td-active {
  color: var(--success);
  border-color: var(--success);
  background-color: white;
  border-radius: 0.25rem;
  transition: all 0.33s;
  padding: 4px 8px;
  margin-left: 0.25rem;
  background-color: rgba(40, 199, 111, 0.1);
  border: 1px solid var(--success);
}

.social-btn{
  border: none;
  background-color: transparent;
}

.pending-badge{
  border: 1px solid #fbbc0b;
  background-color: #edbd5157;
  color: #dfa500;
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 10px;
}

.uplaoding-badge{
  border: 1px solid #00adef;
  background-color: #519ac554;
  color: #00adef;
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 10px;
}

.completed-badge{
  border: 1px solid #029666;
  background-color: #85dd6854;
  color: #029666;
  border-radius: 25px;
  font-size: 12px;
  padding: 3px 10px;
}

.gameRow .gameCard{
  width: 100%;
  background: var(--primary);
  color: white;
  padding: 1rem 1rem;
  border-radius: 5px;
  box-shadow: 0 4px 10px #38414a0f;
  margin-top: 30px;
  position: relative;
}

.gameCard h3 {
  font-size: 1.375rem;
}

.gameRow .gameCard .gameCardIcon {
  font-size: 42px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-color: var(--success);
  color: white;
  border-radius: 5px;
}

.gameRow .gameCard .gameCardIcon {
  font-size: 42px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-color: var(--success);
  color: white;
  border-radius: 5px;
}
.gameRow .gameCard .gameCardIcon {
  font-size: 42px;
  width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  background-color: var(--success);
  color: white;
  border-radius: 5px;
}

.gameCardText {
  padding-left: 20px;
  width: calc(100% - 65px);
}

.gameCardText span{
  font-size: 20px;
  font-weight: 600;
}
.gameCardText p{
  font-size: 16px;
  font-weight: 500;
}

.gameRow .gameCard a {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 4px;
  font-size: 10px;
  padding: 0 5px;
  transition: all 0.3s;
  color: var(--success);
  border: 1px solid var(--success);
  text-decoration: none;
}


.gameCardText {
  padding-left: 20px;
  width: calc(100% - 65px);
}

.gameRow .gameCard {
  background-color: #127681;
}

.gameRow>div:nth-child(2) .gameCard {
  background-color: #342ead;
}

.gameRow>div:nth-child(3) .gameCard {
  background-color: #ff5200;
}

.gameRow>div:nth-child(4) .gameCard {
  background-color: var(--primary);
}

.gameRow .gameCard .gameCardIcon,
.gameRow .gameCard a {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: white;
}
.gameCard::before,
.gameCard::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background-color: #fff;
  clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
  opacity: 0.05;
  z-index: 0;
}

.gameCard::after {
  width: calc(20% + 20px);
}

.gameRow .gameCard a {
  top: 50%;
  transform: translateY(160%);
  z-index: 1;
}

.gameCard::before,
.gameCard::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background-color: #fff;
  clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(40% 0, 100% 0, 100% 100%, 0 100%);
  opacity: 0.05;
  z-index: 0;
}

.gameCard::after {
  width: calc(20% + 20px);
}

.userRow .gameCard {
  background-color: #342ead;
}

.userRow>div:nth-child(2) .gameCard {
  background-color: #0800ab;
}

.userRow>div:nth-child(3) .gameCard {
  background-color: #127681;
}

.userRow>div:nth-child(4) .gameCard {
  background-color: #035aa6;
}

.userRow>div:nth-child(5) .gameCard {
  background-color: #00005c;
}

.userRow>div:nth-child(6) .gameCard {
  background-color: #0062ed;
}

.userRow>div:nth-child(7) .gameCard {
  background-color: #5b00d9;
}

.userRow>div:nth-child(8) .gameCard {
  background-color:#9354ea;
}
