/* @media (max-height: 1300px)  {
  .user-main {
    background-size: 270%;
}
} */
@media  screen and (max-width: 1024px) and (max-height: 1400px)  {
  .user-main {
    background-size: 270%;
}
}

@media  screen and (max-width: 821px) and (max-height: 1180px)  {
  .user-main {
    background-size: 460%!important;
}
}

@media (max-height: 740px)  {
  .iframe-main {
    height: 520px;
    width: 100%;
  }  
}

@media (max-height: 620px)  {
  .iframe-main {
    height: 420px;
    width: 100%;
  }  
}

@media (max-height: 510px)  {
  .iframe-main {
    height: 360px;
    width: 100%;
  }  
}

@media (max-height: 455px)  {
  .iframe-main {
    height: 345px;
    width: 100%;
  }  
}


@media (max-width: 1440px) {
  .user-container-fluid {
    width: 100% !important;
  }

  .user-main {
    background-size: 225%;
  }

  .search-form {
    width: 25%;
}
}

@media (max-width: 1200px) {
  .homeGamesDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .CategoryGamesDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .playGamesDiv {
    grid-template-columns: auto auto;
  }

  .sidebar-container {
    transform: translateX((-100%));
    top: 0;
    left: 0 !important;
    z-index: 1;
  }

  .user-main {
    background-size: 250%;
  }

}

@media (max-width: 991px) {

  .search-form {
    order: 1;
    width: 100%;
  }

  .app-sidebar__togglee {
    display: block;
  }

  .homeGamesDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .CategoryGamesDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .sidebarWrapper,
  .sidebarWrapper .app-sidebar {
    left: -290px;
  }

  .sidebarWrapper.sidebarToggle .app-sidebar,
  .sidebarWrapper.sidebarToggle {
    left: 0px;
  }

  .rightSideWrapper {
    margin-left: 0;
  }

  .slideBtn,
  .slideCloseBtn {
    display: block;
  }

  .sidebar-container {
    transform: translateX((-100%));
    position: absolute;
    bottom: 0;
    left: 0 !important;
    z-index: 1;
  }

  .nav-main {
    height: 90vh;
  }

  .navbar_mobile_active {
    transform: translate(0);
    z-index: 9;
  }

  .nav_small {
    display: none;
  }

  .sidebar-container {
    background: #10163c;
    height: 100vh;
  }

  .close-btn {
    padding: 20px 20px;
    cursor: pointer;
    display: block;
  }

  .close-btn i {
    font-size: 2rem !important;
  }

  .user-main {
    background-size: 300%;
  }

  .home-main {
    height: calc(100vh - 122px);
}

}

@media (max-width: 767px) {
  .homeGamesDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .CategoryGamesDiv {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .play-two {
    width: 100%;
    margin: 0;
  }

  .filterButton {
    border-radius: 100px;
    color: #ffffff;
    padding: 5px 19px;
    border: none;
    margin-right: 15px;
    margin-top: 5px;
    font-size: 12px;
  }

  /* .search-form {
    width: 100%;
  } */

  .home-main {
    height: calc(100vh - 122px);
  }

  .main-height {
    min-height: calc(100vh - 122px);
  }

  .user-main {
    background-size: 415%;
  }
}

@media (max-width: 576px) {

  .fa-user{
    display: block;
  }

  .homeGamesDiv {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .CategoryGamesDiv {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .user-logo {
    width: 163px;
    cursor: pointer;
  }

  .iframe-main {
    width: 100%!important;
    height: 100vh!important;
  }

  .home-main {
    height: calc(100vh - 109px);
  }

  .main-height {
    min-height: calc(100vh - 130px);
  }

  .user-main {
    background-size: 800%;
  }
}

/* @media (max-width: 900px) {
  .games {
    justify-content: end !important;
  }
}

@media (max-width: 900px) and (min-width: 765px) {
  .games {
    justify-content: end !important;
  }
} */