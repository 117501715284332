@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@100;300;400;700;900&display=swap");

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-main {
  background-image: url(../../Images/User/home-bg1.svg);
  background-color: #08233f;
  background-repeat: no-repeat;
  background-size: 198%;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;
  color: white;
  overflow-y: hidden;
}

.home-main {
  height: calc(100vh - 68px);
  overflow-y: auto;
  width: 100vw;
  overflow-x: hidden;
}

.main-height {
  min-height: calc(100vh - 102px) !important;
}

.home-main::-webkit-scrollbar {
  width: 10px;
}

.home-main::-webkit-scrollbar-track {
  background-color: #0a3766b0;
}

.home-main::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.inputSearchIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1b2b52;
  padding: 4px 7px;
  border-radius: 50%;
  cursor: pointer;
}

.home-search {
  background-color: #001327;
  border: 1px solid #121317;
  border-radius: 4px;
  color: #fff !important;
  border-radius: 100px;
  padding: 8px 15px;
}

.home-search::-webkit-input-placeholder {
  color: #c5c4c4;
}

.home-search.form-control:focus {
  background-color: #0f2133;
}

.navbar-btn {
  border: #121317;
  color: #fff;
  display: block;
  background: none;
  font-size: 18px;
}

.search-dropdown .dropdown-btn {
  width: 100%;
}

.games {
  color: white;
  font-weight: 800;
  font-size: 15px;
}

.games i {
  font-size: 17px;
}

.game-btn {
  align-items: center;
  border-radius: 50px;
  font-weight: 800;
  font-size: 20px;
  box-sizing: border-box;
  padding: 8px 16px;
  background: #0a3766b0;
  color: #F9FAFF;
  height: 40px;
  border: none;
}


.search-dropdown .dropdown-toggle::after {
  display: none;
}

.search-form {
  width: 20%;
}

.game-list {
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  background-color: #183c5f;
}

.not-fond {
  color: #fff;
  text-align: center;
  padding: 10px;
}

.user-logo {
  /* width: 300px; */
  width: 200px;
  cursor: pointer;
}

.user-container-fluid {
  width: 95% !important;
  min-height: calc(100vh - 50px);
}

.list-searchgame img {
  width: 50px;
  border-radius: 10px;
  margin-right: 10px;
}

.list-searchgame span {
  color: #fff
}

.list-searchgame:hover {
  background-color: #131a47;
}

.homeGamesDiv {
  color: white;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  /*grid-auto-rows: 200px;*/
  grid-auto-flow: dense;
  /* min-height: 500px; */
}

.playGamesDiv {
  color: white;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  /*grid-auto-rows: 200px;*/
  grid-auto-flow: dense;
}

.gameGridDiv {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.gameName {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  text-align: center;
  color: #fff;
  background: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.3) 100%);
  width: 100%;
  border-radius: 10px;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  z-index: 9;
  pointer-events: none;
  font-size: 15px;
  font-weight: 600;
}

.Carousel-main:hover .gameName {
  opacity: 1;
}


.Carousel-main video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.filterButton-main {
  overflow-x: auto;
  cursor: grab;
}

.gameGridDiv:hover .gameName {
  opacity: 1;
}

.gameGridDiv.big {
  grid-row: span 2;
  grid-column: span 2;
}

.gameGridDiv img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 10px;
}

.gameGridDiv video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.gameGridDiv:hover {
  transform: scale(1.01869) translate(0px, -4px);
  transition-duration: 0.3s;
}


.gameGridDiv .homeVideo,
.Carousel-main .homeVideo {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.gameGridDiv:hover .homeVideo,
.Carousel-main:hover .homeVideo {
  opacity: 1;
}

.gameGridDiv:hover .homeimage,
.Carousel-main:hover .homeimage {
  opacity: 0;
}

.homeimageWithoutImage {
  opacity: 0;
  intrinsicSize: auto;
}

.opacity-show {
  opacity: 1;
  max-width: 100%;
  height: auto;
}

.mostpopular-grid {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.mostpopular-grid.big {
  grid-row: span 2;
  grid-column: span 2;
}

.mostpopular-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.mostpopular-grid video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.mostpopular-grid:hover {
  transform: scale(1.01869) translate(0px, -4px);
  transition-duration: 0.3s;
}

.mostpopular-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.alsolike-grid {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}


.gameGridDiv {
  width: 100%;
  height: 100%;
  cursor: pointer;
}


.alsolike-grid.big {
  grid-row: span 2;
  grid-column: span 2;
}

.alsolike-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.alsolike-grid:hover .gameName {
  opacity: 1;
}

.alsolike-grid:hover {
  transform: scale(1.01869) translate(0px, -4px);
  transition-duration: 0.3s;
}

.alsolike-grid video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.alsolike-grid .homeVideo {
  display: none;
}

.alsolike-grid:hover .homeVideo {
  display: block;
}

.alsolike-grid:hover .homeimage {
  display: none;
}

.bannerImage {
  height: 100%;
}

.CategoryGamesDiv {
  color: white;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  /* / grid-auto-rows: 200px; / */
  grid-auto-flow: dense;
}

.home-description {
  font-weight: 400;
  line-height: 26px;
}

.game-title {
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}

.Carousel-main {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
}

.Carousel-main img {
  border-radius: 10px;
  cursor: pointer;
}

.bannerCarousel {
  width: 98% !important;
  margin: auto;
}

.bannerCarousel .owl-nav button.owl-prev,
.bannerCarousel .owl-nav button.owl-next {
  padding: 0 !important;
  margin: 0;
  font-size: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.bannerCarousel .owl-nav button.owl-prev:hover,
.bannerCarousel .owl-nav button.owl-next:hover {
  background: none;
  opacity: 0.7;
}

.bannerCarousel .owl-nav button.owl-prev {
  left: calc(0% - 25px);
}

.bannerCarousel .owl-nav button.owl-next {
  right: calc(0% - 25px);
}

.bannerCarousel .owl-nav span {
  font-size: 50px;
}

.footer {
  background: #1b1d22;
  color: #70778f;
  position: relative;
  font-size: 12px;
  margin-top: 40px;
}

.footer::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 100%;
  margin-bottom: -1px;
  left: 0;
  background: #1b1d22;
  height: 26px;
  clip-path: polygon(0% 100%, 30% 0%, 36% 100%, 100% 0%, 100% 100%);
}

.input-group.input-group-unstyled input.form-control {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.input-group-unstyled .input-group-addon {
  border-radius: 4px;
  border: 0px;
  background-color: transparent;
}


.Most-popular {
  font-size: 20px;
  font-weight: 600;
}

.play-one {
  width: 20%;
}

.play-two {
  width: 80%;
  margin: 20px;
}

.game-discription {
  padding: 40px 40px;
  background-color: #001327ad;
  border-radius: 40px;
}

.game-discription .game-type {
  font-size: 14px;
  font-weight: 400;
  color: #70778f;
}

.game-discription .game-name {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.game-discription .game-played {
  color: #c0c3ce;
}

.get-lebal {
  background-color: #e2e0e0;
  padding: 7px 14px;
  border-radius: 15px;
}

.likeIcon i {
  font-size: 1.5rem;
  cursor: pointer;
}

.likeIcon i:hover {
  color: #109ddf;
}

.likeIcon span {
  font-size: 18px;
}

.like-icon {
  color: green;
}

.dislike-icon {
  color: red;
}

/* / about page start / */
.aboutDiv * {
  color: white;
}

.aboutMainTitle {
  font-size: 40px;
}

.aboutMainP {
  font-size: 20px;
}

.aboutTitle {
  font-size: 32px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.aboutTitle span {
  font-size: 24px;
}

.aboutP {
  font-size: 16px;
}

/* / about page end /

/ width / */
.filterButton-main::-webkit-scrollbar {
  height: 3px;
  display: none;
}

.iframe-main {
  height: 650px;
  width: 100%;
}

.iframe-main-full {
  height: 100%;
  width: 100%;
}

.frame-full-screen {
  height: 100vh;
  width: 100%;
  position: absolute;
}

.full-screen-div {
  top: 0;
  height: 100vh;
  width: 100%;
  background: #fff;
  left: 0%;
  z-index: 1;
}

.back-span {
  background-color: #fff;
  color: #000;
  top: 0;
  left: 0px;
  padding: 10px 15px;
  margin-top: 60px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 0 10px 10px 0;
  box-shadow: #919191 0px 0px 10px 0px;
  border: none;
  z-index: 5;
}

.back-span i {
  font-size: 20px;
}

.footerLink i {
  font-size: 18px;
  color: #c0c0c0;
}

.footerLink i:hover {
  color: white;
}

.footerNav a {
  text-decoration: none;
  color: white;
  display: inline-block;
  margin: 0 10px;
}

.footerNav a:hover {
  text-decoration: underline;
}

/* / contact page start / */
.contactForm,
.pagenotFound {
  /* / background: #131a47; / */
  background: #232c694a;
  padding: 40px 40px 60px;
  border-radius: 15px;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 10px #232c69;
  box-shadow: 0 0 10px #131a47;
}

.contactForm label {
  font-size: 15px;
}

.contactForm input,
.contactForm textarea {
  background: #00000047 !important;
  border: 1px solid #131a47;
  padding: 10px 15px;
  color: white !important;
  box-shadow: none !important;
}

.contactForm .contactBtn {
  width: 100%;
  padding: 10px 15px;
  text-transform: uppercase;
}

/* / contact page end /

/ sidebar start / */

.sidebar-container {
  background: #08233fb0;
  height: calc(100vh - 68px);
  /* / padding: 1rem 0.8rem; / */
  transition: transform 300ms ease-in-out;
}

.nav-bg {
  background: #08233fb0;
}

.sidebar-toggle-btn {
  font-size: 23px !important;
  cursor: pointer;
}

.nav-main {
  position: relative;
  width: 14rem;
  height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: width linear 0.3s;
}

.nav-main::-webkit-scrollbar {
  display: none;
}

.nav_small {
  width: 3rem;
}

.nav_small .li_navlink a {
  padding: 0px 15px;
}

.nav_small .description {
  opacity: 0;
}

.li_navlink a {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  transition: all ease-in-out 0.2s;
  font-size: 16px;
}

.li_navlink a .description {
  margin-left: 0.5rem;
  font-weight: 400;
  transition: opacity 200ms ease;
}

.li_navlink a svg {
  font-size: 20px;
  flex-shrink: 0;
}

.li_navlink :hover {
  color: #14a8e8;
}

.li_navlink a svg :hover {
  color: #7ed3f9;
}

.li_navlink .active {
  border-left: 5px solid #14a8e8;
  color: #14a8e8;
}

.li_navlink svg .active {
  border-left: 5px solid #109ddf;
  color: #109ddf;
}

.menu-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.sidebar .row #active {
  border-left: 5px solid #109ddf;
  color: #109ddf;
}

.sidebar-container1 {
  left: -30px !important;
}

.navbar_mobile_active {
  transform: translate(0);
}

.close-btn {
  display: none;
}

/* / sidebar end /

/ cookie start / */

.cookie-main {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #000e1c;
  padding: 30px;
  z-index: 999;
  width: 340px;
  border-radius: 15px;
}

/* / cookie end / */

.user-category-image {
  width: 20px
}

/* / privacy start / */

.privacy-main li {
  font-weight: 700;
  font-size: 18px;
  margin-top: 10px;
}

.privacy-child li {
  font-weight: 500;
  font-size: 16px;
}

/* / privacy end / */

.pagenotFound p {
  font-size: 120px;
  font-weight: 700px;
}

.pagenotFound-main {
  height: 100vh;
  align-content: center !important;
}

.pagenotFound span {
  font-size: 50px;
  font-weight: 700px;
}

.PageNotFound-hidden {
  overflow-x: hidden;
}

.pagenotFound-main a {
  background-color: #109ddf;
  padding: 15px 20px;
  font-size: 18px;
  text-decoration: none;
  color: #000e1c;
  border-radius: 10px;
}

.facebook-icon i {
  color: #109ddf
}

.signUp-link {
  color: #000;
  font-size: 14px;
}

.game-select-menu {
  width: 50%;
}

.dash-table-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: gray;
}

.home-des {
  background: #08233fcf;
  border-radius: 10px;
  margin-top: 50px
}

.description-title {
  font-size: 18px;
  font-weight: 600;
}

.description-details {
  font-size: 14px;
  color: #ffffffd6;
}

.gamecategory-description {
  background: #08233fcf;
  border-radius: 10px;
  margin-top: 100px
}

.description-title {
  font-size: 18px;
  font-weight: 600;
}

.description-details {
  font-size: 14px;
  color: #ffffffd6;
}

.gamecategory-link a {
  color: #fff;
}

/* .game-description-box{
  width: 523px;
} */

.active-game-text {
  color: #A48Eff;
  font-weight: 600;
}

.game-heading-text {
  font-size: 20px;
  font-weight: 800;
  margin: 12px 0px;
}

.classification-list .breadcrumb .breadcrumb-item a {
  text-decoration: none !important;
  color: #A48Eff !important;
}

.classification-list-image {
  background-color: #8080804a;
  border-radius: 17px;
  padding: 5px 10px;
  margin-right: 10px;
  width: 100px;
  color: White !important;
  text-decoration: none !important;
}

.classification-list-image:hover {
  background-color: #80808088;
  transition: 0.5s;
  cursor: pointer;
}

.faq-answer {
  font-size: 13px;
}

.common-p-tag-size {
  font-size: 13px;
}

.tag-item {
  background-color: #0C2153;
  border: 2px solid #1574f6;
  border-radius: 30px;
  padding: 10px 20px !important;
  color: white;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.tag-item:hover {
  background-color: #1574f6;
  color: #fff;
  border: 2px solid #9d9d9d
}