@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* @import "~/node_modules/material-design-icons/iconfont/material-icons.css"; */

/* @font-face {
    font-family: 'typicons';
    src: url('fonts/typicons.ttf') format('truetype'),
        url('fonts/typicons.woff') format('woff'),
        url('fonts/typicons.svg#typicons') format('svg');
    font-weight: normal;
    font-style: normal;
} */

body {
  font-family: "inter";
  color: #212529;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  height: auto;
  background-color: #ecf0fa !important;
}

.bg-danger1 {
  background-color: #ee335e !important;
}

.bg-primary1 {
  background-color: #0162e8 !important;
}

.bg-warning1 {
  background-color: #fbbc0b !important;
}

.text-success1 {
  color: #22c03c !important;
}

.text-danger1 {
  color: #ee335e !important;
}

.text-muted1 {
  color: #7987a1 !important;
}

.text-warning1 {
  color: #e8b210 !important;
}

p,
li,
span {
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  font-size: 1.09375rem;
  /* color: #031b4e; */
}

label {
  font-size: 0.85rem;
  font-weight: 500;
  margin-bottom: 5px;
}

*[class*="btn"] {
  transition: all 0.3s;
  font-size: 0.875rem;
}

img {
  max-width: 100%;
  height: auto;
}

/* pagination css start */

.adminPagination {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.adminPagination a {
  font: "robosta, san serif";
  color: #596882;
  display: flex;
  line-height: 1.25;
  position: relative;
  margin: 10px 5px;
  text-decoration: none !important;
  border-radius: 3px;
  background-color: #dde2ef;
  border-width: 0;
  color: #737f9e !important;
  font-weight: 600 !important;
  height: 38px;
  margin-left: 0;
  padding: 0;
  width: 36px;
  border: 1px solid #cdd7ef;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 4px;
  margin-right: 6px !important;
}

.adminPagination a:hover {
  background-color: #0162e8 !important;
  border: 1px solid #0162e8 !important;
  color: #fff !important;
}

.adminPagination .paginationActive a {
  background-color: #00adef ;
  border-color: #00adef ;
  color: #fff !important;
  z-index: 1;
}

.adminPagination .paginationLink {
  align-items: center;
  font-size: 14px;
  border: 1px solid #cdd7ef;
  color: #737f9e;
  display: flex;
  line-height: 1.25;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.paginationLink:hover {
  background-color: #0162e8 !important;
  border: 1px solid #0162e8 !important;
  color: #fff !important;
}

.paginationLink {
  padding: 10px 15px !important;
  width: auto !important;
  border-radius: 4px;
}

.paginationLink:active {
  background-color: #0162e8 !important;
  border-color: #0162e8;
  color: #fff !important;
}

.paginationLinkPrev {
  padding: 10px 15px !important;
  width: auto !important;
  border-radius: 4px;
}

.paginationDisabled a {
  border-color: #d0d7e8;
  color: #737f9e;
  cursor: auto;
  pointer-events: none;
  background-color: #fff !important;
}

/* pagination css end */

/* modal css start */
.userModal .modal-title {
  color: var(--text);
  font-size: 1.125rem;
}

.userModal .btn-close {
  font-size: 10px;
  margin-right: -5px;
}

.userModal .modal-content {
  border-radius: 0.3rem;
}

.userModal h6 {
  color: #34495e;
}

.userModal h4 {
  color: #34495e;
  font-size: 1.25rem;
}

.userModal span {
  color: var(--text);
}

.userModal .modalSubmit {
  background: var(--primary);
  border-color: var(--primary);
}

/* modal css end */
